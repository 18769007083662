<script setup lang="ts">
import { inject } from "vue";
import { useConnectedStore } from "../store";
import type { WalletOptions } from "../types";
import { onConnect } from "../wallet";

const store = useConnectedStore();

const props = defineProps(["supportedChains"]);

store.setSupportedChains(props.supportedChains);
const chainIds = inject<WalletOptions>("WalletOptions")?.chainIds;

async function handleOnConnect() {
  if (chainIds) {
    try {
      await onConnect(chainIds);
    } catch (e) {
      console.log("Error on handleOnConnect:", e);
    }
  } else {
    await onConnect([56]);
  }
}
</script>
<template>
  <div v-show="!store.connected" @click="handleOnConnect">
    <slot> Connect Wallet </slot>
  </div>
</template>
<style lang="scss" scoped></style>
