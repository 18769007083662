<script setup lang="ts">
import { formatDate, formatTime, logoUrl } from "@/helpers";
import { notify } from "@kyvg/vue3-notification";
import { utils } from "ethers";
import Loading from "../components/base/Loading.vue";
import { addDecimals } from "@/helpers";
import { ref, onMounted, inject, reactive, computed, watch } from "vue";
import Chart, { type Dataset } from "@/components/Chart.vue";
import type { IVesting } from "../types/ethers/Vesting";
import {
  claimVestedAmount,
  getAllPools,
  getClaimableAmount,
  getReleasableAmount,
  getVestingEntry,
  hasEntry,
  isAdmin,
} from "@/contracts";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import wp from "../wallet";
import ConnectButton from "../wallet/components/ConnectButton.vue";
import DisconnectButton from "../wallet/components/DisconnectButton.vue";
import { getTokenByAddress } from "@/wallet/chain";
import { onConnect } from "@/wallet/wallet";
import { useConnectedStore } from "@/wallet/store";

const store = useConnectedStore();
const { connected, address } = storeToRefs(store);

const router = useRouter();

const currency = ref("...");
const vestingPools = reactive<Array<IVesting.VestingInfoStructOutput>>([]);
const myPools = reactive<Array<IVesting.VestingInfoStructOutput>>([]);
const activePoolId = ref<number>(0);
const myActivePoolId = ref<number>(0);
const activePool = ref<IVesting.VestingInfoStructOutput | null>(null);
const userEntries = reactive<Array<IVesting.WhitelistInfoStructOutput>>([]);
const activeEntry = ref<IVesting.WhitelistInfoStructOutput | null>(null);
const locked = ref<number>(0);
const available = ref<number>(0);
const unlocked = ref<number>(0);
const initializing = wp.useInitializing();
const admin = ref<boolean>(false);
const claimButtonText = ref<string>("Claim all tokens");

onMounted(async () => {
  setup();
});

async function setup() {
  if (!connected.value) {
    router.push({
      name: "Home",
    });
  }
  const chainId = +import.meta.env.VITE_APP_CHAINID;
  await onConnect([chainId]);
  const token = getTokenByAddress(import.meta.env.VITE_APP_TOKEN_ADDRESS);
  admin.value = await isAdmin();
  currency.value = await token.symbol();
  let _vestingPools = await getAllPools();
  await initializing;
  let hasEntryPromises: Promise<boolean>[] = [];

  // Check async which pools have an entry for this wallet
  hasEntryPromises = _vestingPools.map((_, index) => {
    return hasEntry(index, address.value);
  });
  let hasEntryResult = await Promise.all(hasEntryPromises);

  // Get index of pools where user has entry.
  // Check for true values in boolean array
  let hasEntryResultIndexes = [...hasEntryResult.keys()].filter(
    (i) => hasEntryResult[i],
  );
  // Set
  let _myPools = _vestingPools.filter((_, index) =>
    hasEntryResultIndexes.includes(index),
  );

  Object.assign(myPools, _myPools);
  Object.assign(vestingPools, _vestingPools);

  if (connected.value) {
    await fetchUserEntries();
  }

  if (myPools.length == 0) {
    router.push({
      name: "NotFound",
    });
  } else {
    setActivePool(0);
  }
}

watch(connected, (isConnected: boolean) => {
  if (!isConnected) {
    activePool.value = null;
    activeEntry.value = null;
    router.push({
      name: "Home",
    });
  } else {
    setup();
  }
});

async function fetchUserEntries() {
  const promises: Array<Promise<IVesting.WhitelistInfoStructOutput>> = [];
  for (const pool of myPools) {
    promises.push(getVestingEntry(vestingPools.indexOf(pool), address.value));
  }
  Object.assign(userEntries, await Promise.all(promises));
}

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const plByMonth = computed(() => {
  if (activePool.value && activeEntry.value) {
    let data: Dataset = {
      labels: [],
      datasets: [
        {
          label: "Unlocked",
          data: [],
          backgroundColor: "#9558d6",
        },
      ],
    };
    const startDate = new Date(+activePool.value?.startDate * 1000);
    const cliffDate = new Date(+activePool.value.cliff * 1000);
    const endDate = new Date(
      +activePool.value?.startDate * 1000 + +activePool.value?.duration * 1000,
    );
    const initialUnlock = +utils.formatEther(
      activePool.value.initialUnlock.mul(activeEntry.value.amount).div(1000),
    );
    24;
    const remaining = activeEntry.value.amount
      .sub(
        activePool.value.initialUnlock.mul(activeEntry.value.amount).div(1000),
      )
      .div(10 ** 9)
      .div(10 ** 9);
    for (
      let i = startDate.getTime();
      i < endDate.getTime() + 3600 * 672 * 1000;
      i += 3600 * 24 * 1000
    ) {
      const d = new Date(i);
      if (
        d.getTime() >= startDate.getTime() &&
        d.getTime() < cliffDate.getTime()
      ) {
        data.datasets[0].data.push(initialUnlock);
      } else if (
        d.getTime() >= cliffDate.getTime() &&
        d.getTime() < endDate.getTime()
      ) {
        data.datasets[0].data.push(
          initialUnlock +
            +remaining
              .mul(i - cliffDate.getTime())
              .div(endDate.getTime() - cliffDate.getTime()),
        );
      } else {
        data.datasets[0].data.push(
          +utils.formatEther(activeEntry.value.amount),
        );
      }
      data.labels.push(`${months[d.getMonth()]} ${d.getFullYear() - 2000}`);
    }
    return data;
  }
  return {} as Dataset;
});

const claimTokens = async () => {
  try {
    if (available.value == 0) {
      return;
    }
    if (activePool.value?.revoked) {
      notify({
        text: "Pool revoked",
        type: "error",
      });
      return;
    }
    let result = await claimVestedAmount(activePoolId.value, address.value);
    if (result) {
      notify({
        text: "Claimed successfully",
        type: "success",
      });
    } else {
      notify({
        text: "Claim failed",
        type: "error",
      });
    }
  } catch (e) {
    notify({
      text: "Claim failed",
      type: "error",
    });
  }
};
/**
 *
 * @param myPoolIndex index of myPools
 */
async function setActivePool(myPoolIndex: number) {
  if (myPools[myPoolIndex].revoked == true) {
    myPoolIndex += 1;
  }

  const poolId = vestingPools.indexOf(myPools[myPoolIndex]);
  if (vestingPools[poolId] !== undefined) {
    activePoolId.value = poolId;
    myActivePoolId.value = myPoolIndex;
    activePool.value = vestingPools[poolId];
    activeEntry.value = userEntries[myPoolIndex];
    const total = +utils.formatEther(activeEntry.value.amount);
    locked.value =
      total -
      (await getClaimableAmount(poolId, address.value)) -
      +utils.formatEther(activeEntry.value.distributedAmount);
    available.value = await getReleasableAmount(poolId, address.value);
    if (available.value == 0) {
      claimButtonText.value = "No Tokens to claim";
    } else {
      claimButtonText.value = "Claim all tokens";
    }
    unlocked.value = total - locked.value;
  }
}

async function addToken() {
  try {
    await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: import.meta.env.VITE_APP_TOKEN_ADDRESS,
          symbol: currency.value,
          decimals: 18,
        },
      },
    });
  } catch (error) {
    console.log(error);
  }
}
</script>

<template>
  <div class="wrapper mauto rel auto-h">
    <div class="logo abs">
      <img src="../assets/fullhouse-logo.png" />
    </div>
    <div class="manage abs round centerflex">
      <div class="add-currency" @click="addToken()">Add FH</div>
      <div
        v-if="admin"
        class="add-currency"
        @click="$router.push({ name: 'Manage' })"
      >
        Manage
      </div>
      <DisconnectButton />
      <ConnectButton />
    </div>
    <div>
      <div class="sides">
        <div v-if="activePool" class="menu">
          <div v-for="(pool, index) in myPools" :key="index" class="item fat">
            <div
              v-if="pool.revoked === false"
              @click="setActivePool(index)"
              :class="{ active: myActivePoolId == index }"
            >
              {{ pool.name }}
            </div>
          </div>
        </div>

        <div class="holder">
          <div class="title">Vesting FH</div>
          <div class="container">
            <div class="box box1 round">
              <div class="title">Vesting period</div>
              <!-- <div class="subtitle fat">
                Some explaining text about vesting period
              </div> -->
              <div class="date-wrap">
                <div class="name fat">Start date</div>
                <!-- <div class="date-time" v-if="activePool">
                  <div class="date fat">
                    {{ toHTMLdate(activePool.startDate.toNumber()) }}
                  </div>
                  <div class="time">{{ activePool.startDate }}</div>
                </div> -->
                <div class="date-time">
                  <div class="date fat" v-if="activePool">
                    {{ formatDate(activePool.startDate) }}
                  </div>
                  <div v-if="activePool" class="time">
                    {{ formatTime(activePool.startDate) }}
                  </div>
                  <div v-else-if="connected" class="date fat">Loading...</div>
                </div>
              </div>

              <div class="date-wrap">
                <div class="name fat">Cliff</div>
                <div class="date-time">
                  <div class="date fat" v-if="activePool">
                    {{ formatDate(activePool.cliff) }}
                  </div>
                  <div v-if="activePool" class="time">
                    {{ formatTime(activePool.cliff) }}
                  </div>
                  <div class="date fat" v-else-if="connected">Loading...</div>
                </div>
              </div>
              <div class="date-wrap">
                <div class="name fat">End date</div>
                <div class="date-time">
                  <div class="date fat" v-if="activePool">
                    {{
                      formatDate(activePool.startDate.add(activePool.duration))
                    }}
                  </div>
                  <div v-if="activePool" class="time">
                    {{
                      formatTime(activePool.startDate.add(activePool.duration))
                    }}
                  </div>
                  <div class="date fat" v-else-if="connected">Loading...</div>
                </div>
              </div>
            </div>
            <div class="box box2 round">
              <div class="title">Vesting summary</div>
              <!-- <div class="subtitle fat">
                Some explaining text about vesting period
              </div> -->
              <div class="date-wrap">
                <div class="name fat">Locked</div>
                <div class="value" v-if="activeEntry && activePool">
                  {{ addDecimals(locked) }} FH
                </div>
              </div>
              <div class="date-wrap">
                <div class="name fat">Claimed</div>
                <div class="value" v-if="activeEntry">
                  {{
                    addDecimals(
                      +utils.formatEther(activeEntry.distributedAmount),
                    )
                  }}
                  FH
                </div>
                <div class="abs" style="right: 20px" v-else>
                  <Loading v-if="connected" height="80" width="80" />
                </div>
              </div>
              <div class="date-wrap">
                <div class="name fat">Unlocked</div>
                <div class="value" v-if="activeEntry">
                  {{ addDecimals(unlocked) }} FH
                </div>
              </div>
              <div class="date-wrap">
                <div class="name fat">Available tokens</div>
                <div class="value" v-if="activeEntry">
                  {{ addDecimals(available) }} FH
                </div>
              </div>
              <div
                v-if="activePool && address && connected"
                class="claim fat mauto btn round blue"
                @click="claimTokens()"
              >
                {{ claimButtonText }}
              </div>
              <ConnectButton v-else />
            </div>
            <div class="box box3 round">
              <div class="title">Schedule</div>
              <div class="subtitle fat">Progress of tokens</div>
              <Chart v-if="activeEntry" :data="plByMonth" />
              <Loading v-else-if="connected" width="100" height="100" />
            </div>
          </div>
        </div>
        <div class="abs ellipse ellipse1"></div>
        <div class="abs ellipse ellipse2"></div>
        <div class="abs ellipse ellipse3"></div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 2;
}

.wrapper {
  font-family: "Open Sans";
  font-style: normal;
}

.wrapper {
  width: 100%;
  height: auto;
  float: left;
  padding-bottom: 50px;
  overflow-x: hidden !important;
  overflow-y: hidden;
  color: var(--color-text-primary);
  background: var(--color-dark-manage-vesting);

  .title {
    text-align: left;
  }

  .logo {
    width: 200px;
    height: 100px;
    top: 20px;
    left: 20px;

    img {
      width: 100%;
    }
  }

  .manage {
    background: var(--color-dark-manage-vesting);
    top: 20px;
    right: 20px;
    color: var(--color-text-primary);

    .add-currency {
      height: 50px;
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      border: 1px transparent solid;
      cursor: pointer;

      &:hover {
        border: 1px #367de7 solid;
        color: #367de7;
      }
    }
  }

  .connect {
    height: 100vh;
    width: 100%;

    .wallet {
      top: 0;
      right: 0;
    }
  }

  .holder {
    width: 100%;
    height: auto;
    float: left;
    margin-top: 80px;
    position: relative;
    z-index: 1;
  }

  .sides {
    margin-top: 200px;
    width: 90%;
    margin: 0 auto;

    .menu {
      margin: auto;
      min-height: 80px;
      align-items: center;
      width: 40%;
      display: flex;
      justify-content: space-around;

      .item,
      .router-link {
        z-index: 2;
        color: #565c6b;
        height: 30px;
        width: auto;
        text-decoration: none;
        cursor: pointer;
      }

      .active {
        color: #fff;
      }
    }

    .container {
      display: grid;
      width: 100%;
      grid-template-columns: 4fr 10fr;
      grid-template-rows: 240px 356px 20px;
      gap: 20px;

      .box {
        padding: 25px;
        background: rgba(255, 255, 255, 0.05);
        box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(15px);
        /* Note: backdrop-filter has minimal browser support */

        border-radius: 20px;

        .title {
          text-align: left;
          margin-bottom: 5px;
        }

        .subtitle {
          text-align: left;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          /* identical to box height */

          color: #636363;
        }

        .date-wrap {
          width: 100%;
          display: flex;
          justify-content: space-between;
          grid-template-columns: 1fr 1fr;
          margin: 10px 0;
        }

        .name {
          font-weight: 800;
          font-size: 15px;
          line-height: 152.4%;

          letter-spacing: 0.035em;

          color: #ffffff;
        }

        .date-time {
          text-align: right;
          color: #9558d6;
        }

        .date {
          color: #9558d6;
          font-weight: 400;
          font-size: 16px;
          text-align: right;
          letter-spacing: 0.035em;
          margin-bottom: 4px;
        }

        .time {
          float: right;
          color: #636363;
          font-weight: 100;
          font-size: 14px;
        }

        .claim {
          width: 80%;
        }
      }

      .box1 {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
      }

      .box2 {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        z-index: 1; // bigger than ellipse in claimvesting

        .date-wrap {
          margin: 25px 0;
        }
      }

      .box3 {
        grid-column: 2 / 2;
        grid-row: 1 / 4;
      }
    }

    .ellipse {
      z-index: 0;
    }

    .ellipse1 {
      width: 1274px;
      height: 1274px;
      left: -440px;
      top: 679px;

      background: #bd3636;
      opacity: 0.15;
      filter: blur(350px);
    }

    .ellipse2 {
      width: 818px;
      height: 818px;
      left: 614px;
      top: 56px;

      background: #9a36bd;
      opacity: 0.1;
      filter: blur(350px);
    }

    .ellipse3 {
      width: 2760px;
      height: 2760px;
      left: 42px;
      top: 765px;

      background: #b236bd;
      opacity: 0.15;
      filter: blur(350px);
    }
  }
}

@media only screen and (max-width: 1280px) {
  .wrapper .sides .container {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 350px 480px 20px;
  }

  .wrapper .sides .container .box2 {
    grid-column: 2/2;
    grid-row: 1/2;
  }

  .wrapper .sides .container .box3 {
    grid-column: 3/1;
    grid-row: 2/4;
  }
}

@media screen and (max-width: 1024px) {
  .wrapper .sides .menu {
    margin-top: 80px;
  }

  .wrapper .holder {
    margin-top: 0;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    width: 100vw;
  }

  .sides {
    width: 90%;
  }

  .wrapper .sides .container {
    grid-template-columns: 1fr;
    grid-template-rows: 350px 350px 550px;
    gap: 20px 0;
  }

  .wrapper .sides .container .box2 {
    grid-column: 1/2;
    grid-row: 1/2;
  }

  .wrapper .sides .container .box1 {
    grid-column: 1/2;
    grid-row: 2/2;
  }

  .wrapper .sides .container .box3 {
    grid-column: 3/1;
    grid-row: 3/4;
  }
}

@media only screen and (max-width: 700px) {
  // .sides {
  //   min-width: 90px !important;
  //   width: 100%;
  // }
  .sides {
    width: 90%;

    .container {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr;
      grid-template-rows: 240px 356px 516px;
      gap: 20px;

      .box1 {
        grid-column: 1 / 1;
        grid-row: 1 / 2;
      }

      .box2 {
        grid-column: 1 / 1;
        grid-row: 2 / 3;

        .date-wrap {
          margin: 25px 0;
        }
      }

      .box3 {
        grid-column: 1 / 1;
        grid-row: 3 / 4;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .sides {
    width: 100%;
  }
}
</style>
